const About: React.FC = () => {
  return (
    <div
      className="px-6 sm:px-10 lg:px-16 py-20 sm:py-32 lg:py-40 flex md:flex-row flex-col justify-between items-center max-w-6xl mx-auto 2xl:max-w-6xl gap-6"
      data-aos="fade-up"
    >
      <div className="flex flex-col items-start sm:items-center md:items-start lg:items-center gap-2 md:w-2/3 text-5xl sm:text-6xl md:text-7xl md:mr-8 md:mb-0 mb-8 font-semibold relative cursor-default">
        <span>Curating Experiences,</span>
        <span>Building products,</span>
        <span>Mockups to Code,</span>
        <span>
          Love for{" "}
          <span className="text-sky-500 cursor-pointer peer/css">CSS</span>{" "}
          {"<"}3
          <img
            src={"https://cdn.servatom.com/Portfolios/v1/tailwind.png"}
            loading="lazy"
            alt="tailwind"
            className="w-36 absolute bottom-8 -left-6 opacity-0 rotate-[-10deg] peer-hover/css:translate-x-4 peer-hover/css:-translate-y-4 peer-hover/css:opacity-100 peer-hover/css:rotate-[-25deg] transition-all duration-500 ease-in-out"
          />
        </span>
      </div>

      <p className="text-lg leading-6 font-thin text-justify md:w-1/3 relative">
        I am a full-stack developer building experiences for the web. With over
        2 years of experience in React and related technologies, I have worked
        on a variety of projects ranging from building websites to building
        full-stack web applications. I am in love with CSS and expertise in
        transforming UI mockups into high quality code. Learning Swift nowadays
        and have a newfound interest in devops too.
        <br />
        Need Frontend? I am your go-to guy ;P
        <br /> <br />
        P.S.- Co-founded{" "}
        <a
          className="font-semibold underline peer/servatom tracking-wide"
          href="https://github.com/servatom"
          target="_blank"
          rel="noreferrer"
        >
          @Servatom{" "}
        </a>
        with my friends, a playground for tech enthusiasts like myself.
        <img
          src={"https://cdn.servatom.com/Portfolios/v1/servatom.jpeg"}
          loading="lazy"
          alt="servatom"
          className="absolute opacity-0 w-full rotate-[10deg] -bottom-[40%] sm:-bottom-[100%] md:-bottom-[40%] right-0  peer-hover/servatom:translate-x-2 peer-hover/servatom:-translate-y-8 peer-hover/servatom:opacity-100 peer-hover/servatom:rotate-[-10deg] transition-all duration-500 ease-in-out"
        />
      </p>
    </div>
  );
};

export default About;
