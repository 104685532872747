import Hero from "./components/Hero";
import About from "./components/About";
import Resume from "./components/Resume/Resume";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, lazy, Suspense } from "react";

const Footer = lazy(() => import("./components/Footer/Footer"));
const FunFact = lazy(() => import("./components/FunFact"));

function App() {
  document.addEventListener("visibilitychange", (e) => {
    if (document.visibilityState === "visible") {
      document.title = "Yashvardhan Arora";
    } else {
      document.title = "👀 Come back soon!";
    }
  });
  useEffect(() => {
    AOS.init({
      duration: 500,
      once: false,
      easing: "ease-in-out",
      offset: 150,
      delay: 200,
    });
    AOS.refresh();
  }, []);
  return (
    <div className="text-[#fefefe]">
      {/* {!isLoaded && <Loader />} */}
      <Hero />
      <section className="bg-[#191919] text-[#c9c9c9]">
        <About />
        <Resume />
        <Suspense fallback={<div></div>}>
          <FunFact />
          <Footer />
        </Suspense>
      </section>
    </div>
  );
}

export default App;
