const Heading: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = (props) => {
  const { children } = props;
  return (
    <h2
      className="text-xl md:text-2xl font-semibold"
      style={{
        fontFamily: "Times New Roman",
      }}
    >
      {children}
    </h2>
  );
};

export default Heading;
