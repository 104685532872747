const List: React.FC<{
  bullets: string[];
}> = (props) => {
  const { bullets = [] } = props;
  return (
    <ul
      className="list-inside text-sm md:text-base ml-0 md:ml-2 py-2"
      style={{
        fontFamily: "'Lato', sans-serif",
      }}
    >
      {bullets.map((bullet, index) => (
        <li key={index} className="flex flex-row items-start font-normal">
          <span className="w-1 h-1 md:w-2 md:h-2 aspect-square bg-[#4a4a4a] rounded-full mr-2 mt-2"></span>
          <span>{bullet}</span>
        </li>
      ))}
    </ul>
  );
};

export default List;
