import Section from "./Section";
import blob1 from "../../assets/blob1.svg";
import blob3 from "../../assets/blob3.svg";
import Heading from "./Heading";
import SubHeading from "./SubHeading";
import { resumeData } from "../../config";
import List from "./List";
import { useEffect, useRef, lazy, Suspense } from "react";
import Skills from "./Skills";
// import Project from "./Project";

const Project = lazy(() => import("./Project"));

const Resume: React.FC = () => {
  const resumeRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleScroll = () => {
      const resume = resumeRef.current;
      if (!resume) return;

      const scrollPosition = window.scrollY;
      const resumePosition = resume.offsetTop + resume.offsetHeight / 1.5;
      const distance = resumePosition - scrollPosition;

      resume.style.transform = `translateY(${distance * 0.1}px)`;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="px-4 md:px-16 py-0 relative w-full" id="resume">
      <div
        className="p-4 md:p-16 max-w-5xl mx-auto rounded-xl bg-gray-200 bg-opacity-5 backdrop-blur-2xl flex flex-col items-center relative z-10 -top-32 md:top-0 "
        ref={resumeRef}
      >
        <div className="w-40 h-16 md:w-80 md:h-32 rounded-t-full bg-yellow-500 bg-opacity-90 backdrop-blur-lg absolute -translate-y-full shadowClip shadow-black z-20">
          <div className="w-16 h-16 md:w-32 md:h-32 rounded-full absolute bg-yellow-500 flex items-center justify-center left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="w-8 h-8 md:w-16 md:h-16 rounded-full bg-[#191919]"></div>
          </div>
        </div>
        <div className="bg-[#ffffff94] backdrop-blur-2xl p-4 md:p-12 pb-0 w-full md:-mt-4 text-[#242424]">
          {resumeData.map((section, index) => {
            return (
              <Section title={section.title} key={index}>
                {section.data.map((data, index) => {
                  return (
                    <div key={index} className="w-full py-2">
                      <Heading>{data.title}</Heading>
                      <SubHeading>
                        {data.subtitle.map((item, idx) => {
                          return <span>{item}</span>;
                        })}
                      </SubHeading>
                      {data.bullets && <List bullets={data.bullets} />}
                      {data.project && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <Project project={data.project} index={index} />
                        </Suspense>
                      )}
                    </div>
                  );
                })}
                {section.skills && <Skills skills={section.skills} />}
              </Section>
            );
          })}
        </div>
      </div>
      {/* animate the blob's position */}
      <img
        src={blob1}
        loading="lazy"
        alt="blob1"
        className="blob1"
        draggable={false}
      />
      <img
        src={blob3}
        loading="lazy"
        alt="blob2"
        className="blob2"
        draggable={false}
      />
    </div>
  );
};

export default Resume;
