const Tag: React.FC<{ tag: string }> = (props) => {
  const { tag } = props;
  return (
    <div
      className="flex flex-row items-center justify-center px-2 py-1 bg-[#5e5e5e] rounded-md mx-1 my-1"
      style={{
        fontFamily: "'Poppins', sans-serif",
      }}
    >
      <span className="text-sm font-medium text-white tracking-wide">
        {tag}
      </span>
    </div>
  );
};

export default Tag;
