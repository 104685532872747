import Tag from "./Tag";

const Skills: React.FC<{
  skills: {
    [key in string]: string[];
  };
}> = ({ skills }) => {
  return (
    <div className="flex flex-row flex-wrap justify-center items-center gap-3">
      {Object.keys(skills).map((skill, index) => {
        return (
          <div
            key={index}
            className="flex flex-row flex-wrap items-center justify-center relative px-2 py-3 rounded-md my-4 outline outline-1 outline-[#5d5d5d]"
          >
            <span className="text-base font-normal absolute -top-[10px] bg-[#a6a6a6] left-4 py-0 px-1 leading-4 tracking-wider">
              {skill}
            </span>
            {skills[skill].map((tag, idx) => {
              return <Tag tag={tag} key={idx} />;
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Skills;
