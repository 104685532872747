const sd1 =
  "https://cdn.servatom.com/Portfolios/v1/projects/ServDomain/servdomain1.jpeg";
const sd2 =
  "https://cdn.servatom.com/Portfolios/v1/projects/ServDomain/servdomain2.jpeg";
const sd3 =
  "https://cdn.servatom.com/Portfolios/v1/projects/ServDomain/servdomain3.jpeg";
const sd4 =
  "https://cdn.servatom.com/Portfolios/v1/projects/ServDomain/servdomain4.jpeg";

const gradeyard1 =
  "https://cdn.servatom.com/Portfolios/v1/projects/Gradeyard/gradeyard1.jpeg";
const gradeyard2 =
  "https://cdn.servatom.com/Portfolios/v1/projects/Gradeyard/gradeyard2.jpeg";
const gradeyard3 =
  "https://cdn.servatom.com/Portfolios/v1/projects/Gradeyard/gradeyard3.jpeg";
const gradeyard4 =
  "https://cdn.servatom.com/Portfolios/v1/projects/Gradeyard/gradeyard4.jpeg";

const quizzy1 =
  "https://cdn.servatom.com/Portfolios/v1/projects/Quizzy/quizzy1.jpeg";
const quizzy2 =
  "https://cdn.servatom.com/Portfolios/v1/projects/Quizzy/quizzy2.jpeg";
const quizzy3 =
  "https://cdn.servatom.com/Portfolios/v1/projects/Quizzy/quizzy3.jpeg";
const quizzy4 =
  "https://cdn.servatom.com/Portfolios/v1/projects/Quizzy/quizzy4.jpeg";

const notefy1 =
  "https://cdn.servatom.com/Portfolios/v1/projects/Notefy/notefy1.jpeg";
const notefy2 =
  "https://cdn.servatom.com/Portfolios/v1/projects/Notefy/notefy2.jpeg";
const notefy3 =
  "https://cdn.servatom.com/Portfolios/v1/projects/Notefy/notefy3.jpeg";
const notefy4 =
  "https://cdn.servatom.com/Portfolios/v1/projects/Notefy/notefy4.jpeg";

const servDomain = [sd1, sd2, sd3, sd4];
const gradeyard = [gradeyard1, gradeyard2, gradeyard3, gradeyard4];
const quizzy = [quizzy1, quizzy2, quizzy3, quizzy4];
const notefy = [notefy1, notefy2, notefy3, notefy4];

export interface IResumeData {
  title: string;
  data: IResumeSection[];
  skills?: {
    [key in string]: string[];
  };
}

export interface IResumeSection {
  title: string;
  subtitle: string[];
  bullets?: string[];
  project?: {
    description: string;
    links?: {
      [key in string]: string;
    };
    images?: string[];
  };
}

export const resumeData: IResumeData[] = [
  {
    title: "Education 📚",
    data: [
      {
        title: "Thapar Institute of Engineering and Technology, Patiala",
        subtitle: ["B.E. Computer Engineering", "9.31 CGPA"],
      },
      {
        title: "Salwan Public School, New Delhi",
        subtitle: ["Class XII, CBSE", "97.6%"],
      },
    ],
  },
  {
    title: "Experience 👨🏼‍💻",
    data: [
      {
        title: "JPMorgan Chase & Co.",
        subtitle: ["Software Engineering Intern", "Jan 2024 - Present"],
        bullets: [
          "Designed and developed a new iteration of an existing internal tool from scratch, adding new features, improving design and architecture",
          "Helped achieve minimal clicks and screens for the UI, took feedback to improve UX.",
          "Onboarded 12+ teams (80+ people) for the first MVP of the product, utilising the tool's full capabilities.",
        ],
      },
      {
        title: "Zuddl",
        subtitle: ["Frontend Engineer - Intern", "Sept 2022 - Mar 2023"],
        bullets: [
          "Contributed to Zuddl Setup and Studio, built two full-fledged features for production.",
          "Experienced in utilizing industry-level technologies like NX-Workspaces and React Query.            ",
          "Delivered a workshop on typescript and good practices to full-time employees.",
        ],
      },
      {
        title: "UrbanTV",
        subtitle: ["Frontend Developer - Intern", "May 2022 - Aug 2023"],
        bullets: [
          "Developed the entire front-end of the live e-commerce platform using Typescript, Remix, and Tailwind CSS.        ",
          "Able to reproduce User Interface designs using code with high accuracy.",
          "Achieved integration with Firebase and Facebook using SDK",
          "Made own typescript-based npm package for custom icons for React.",
        ],
      },
      {
        title: "EvolvFit Pvt. Ltd.",
        subtitle: ["Software Developer - Intern", "Sept 2021 - Nov 2021"],
        bullets: [
          "Contributed to enhancing the front-end of the main website and MVP as a React Developer.",
          "Worked on the EvolvFit app using React-Native and developed a couple of functional screens.",
        ],
      },
      {
        title: "Freelancing",
        subtitle: ["Web Development"],
        bullets: [
          "Developed a graphical dashboard for investment portfolio management from scratch using MERN stack.",
          "Developed a slot booking portal and a complex admin dashboard for a mental health club at my college. Implemented in React using Ant Design, Tailwind, Typescript and React Query.",
        ],
      },
    ],
  },
  {
    title: "Projects 📦",
    data: [
      {
        title: "ServDomain",
        subtitle: ["MERN, Docker, CI/CD, Firebase, Cloudflare API, Stripe API"],
        project: {
          description:
            "A marketplace for subdomain lending, focussing on college students and developers. Made it possible for young learners to configure custom CNAME, A and TXT records without owning the domain name. Monthly and annual subscription models.",
          links: {
            "Deployed project": "https://domains.servatom.com/",
          },
          images: servDomain,
        },
      },
      {
        title: "Gradeyard",
        subtitle: [
          "RemixJS, Prisma, Tailwind CSS, Express, Nodejs, Websockets",
        ],
        project: {
          description:
            "A full-stack judging and marksheet web app which can be integrated with Devfolio. Being a hackathon organiser myself and also having participated in many, I know the hassle of judging and grading. This app aims to solve that problem. Used websockets for real-time updates. Work still in progress 🚧.",
          images: gradeyard,
        },
      },
      {
        title: "Quizzy",
        subtitle: ["Typescript, React, Tailwind CSS, Firebase, Firestore"],
        project: {
          description:
            "The simplest quiz application on the internet. Creating or taking a quiz was never this easy. Implemented Authorization using Firebase Google OAuth and context API, used Firestore as database. ",
          links: {
            "Deployed project": "https://quizzy.servatom.com/",
          },
          images: quizzy,
        },
      },
      {
        title: "Notefy",
        subtitle: ["React, Django Rest Framework, Postgres"],
        project: {
          description:
            "An intiutive note-making app having features like dark/light mode, email verification and password reset. Try it ;)",
          links: {
            "Deployed project": "https://notefy.servatom.com/",
          },
          images: notefy,
        },
      },
    ],
  },
  {
    title: "Skills 🚀",
    data: [],
    skills: {
      Domains: ["Web Development", "UI", "Scripting", "IoT", "Video Editing"],
      Languages: [
        "Typescript",
        "Javascript",
        "C++",
        "C",
        "HTML",
        "CSS",
        "R",
        "Python",
        "Java",
      ],
      "Frameworks and Libraries": [
        "React",
        "NextJS",
        "RemixJS",
        "Tailwind CSS",
        "Ant Design",
        "Express",
        "NodeJS",
        "MongoDB",
        "Prisma",
        "Firebase",
        "Bootstrap",
        "NX Workspaces",
        "React Query",
        "Spring Boot",
      ],
      "Developer Tools and Platforms": [
        "Git",
        "Github",
        "Docker",
        "Github Actions",
        "AWS EC2",
        "VS Code",
        "Nginx",
        "NPM",
        "Postman",
        "Figma",
        "Spline 3D",
        "Adobe Premiere Pro",
        "Adobe After Effects",
      ],
    },
  },
];

export const funFacts = [
  "I love doing astrophotography",
  "I love to play guitar",
  "I like cooking",
  "I can sing by whistling quite well",
  "I can play keyboard and percussions",
  "I like shooting and editing documentaries",
  `I won the Code For Good 2023 hackathon organised by 
                    JPMorgan Chase & co., Hyderabad`,
];
