import { ISectionProps } from "./types";

const Section: React.FC<ISectionProps> = (props) => {
  const { title, children, className } = props;
  return (
    <div
      className={"mt-4 mb-8 p-2 flex flex-col items-start " + className}
      data-aos="fade"
    >
      <h1 className="text-4xl md:text-5xl font-semibold tracking-wide">
        {title}
      </h1>
      <div className="w-full h-0.5 bg-[#8d8d8d] md:mb-4 mb-1"></div>
      {children}
    </div>
  );
};

export default Section;
