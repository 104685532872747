const SubHeading: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = (
  props
) => {
  const { children } = props;
  return (
    <h2
      className="text-base md:text-lg italic flex flex-col items-start md:flex-row md:items-center md:justify-between w-full"
      style={{
        fontFamily: "Times New Roman",
      }}
    >
      {children}
    </h2>
  );
};

export default SubHeading;
