import { useEffect, useRef, useState, lazy, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import fallbackImg from "../assets/fallback.png";
import table from "../assets/table.png";
import { IoSettings, IoSettingsSharp } from "react-icons/io5";
import { Application, SPEObject, SplineEvent } from "@splinetool/runtime";
// import Spline from "@splinetool/react-spline";
const Spline = lazy(() => import("@splinetool/react-spline"));

const Hero: React.FC = () => {
  const headingRef = useRef<HTMLDivElement>(null);
  const splineRef = useRef<HTMLDivElement | HTMLImageElement>(null);
  const avatarRef = useRef<HTMLImageElement>(null);
  const [isSplineLoaded, setIsSplineLoaded] = useState(false);
  const mac = useRef<SPEObject>();
  const clipboard = useRef<SPEObject>();
  const iphone = useRef<SPEObject>();
  const github = useRef<SPEObject>();
  // 48D71D7A-15DB-4EF8-B086-15FA64DBF3B0 // mac
  // b5086d81-28ee-48b6-b329-418ddb3c127e // clipboard
  // B7001739-E5F0-464C-8384-E614DDE05436 // iphone
  // 9243962b-6620-456c-8ee6-5f4082e8ba6d // github

  const activeElementIds = [
    "48D71D7A-15DB-4EF8-B086-15FA64DBF3B0",
    "b5086d81-28ee-48b6-b329-418ddb3c127e",
    "B7001739-E5F0-464C-8384-E614DDE05436",
    "9243962b-6620-456c-8ee6-5f4082e8ba6d",
  ];

  useEffect(() => {
    const handleScroll = () => {
      const heading = headingRef.current;
      const spline = splineRef.current;
      const avatar = avatarRef.current;
      if (!heading || !avatar || !spline) return;

      // Calculate the position of the heading relative to the viewport
      const scrollPosition = window.scrollY;
      const headingPosition = heading.offsetTop;
      const distance = headingPosition - scrollPosition;

      // Apply the parallax effect by updating the transform property
      heading.style.transform = `translateY(${distance * 0.2}px)`;
      spline.style.transform = `translateY(${distance * 0.15}px)`;
      avatar.style.transform = `translate(-50%,calc(${
        distance * 0.09
      }px - 50%))`;
    };

    // Add event listener for scroll event
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [headingRef, splineRef]);

  const onMouseHover = (e: SplineEvent) => {
    console.log(e.target.id);
    if (activeElementIds.includes(e.target.id)) {
      console.log("hovered");
      document.body.style.cursor = "pointer";
    } else {
      document.body.style.cursor = "default";
    }
  };

  return (
    <div className="flex flex-col justify-between py-12 md:py-24 pb-0 md:px-16 min-h-[50vh] md:min-h-screen">
      <div className="mx-auto pb-10" ref={headingRef}>
        <h5 className="text-left  sm:text-xl">Hi 👋, I am</h5>
        <h1
          className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl font-semibold "
          style={{
            fontFamily: "'Kaushan Script', cursive",
          }}
        >
          Yashvardhan
        </h1>
        <h1
          className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-semibold text-right relative -top-2 sm:-top-3 lg:-top-6"
          style={{
            fontFamily: "'Kaushan Script', cursive",
          }}
        >
          Arora
        </h1>
      </div>
      <div className={`xl:px-32 py-0 relative`} id="table">
        <ErrorBoundary
          fallback={
            <img
              src={fallbackImg}
              alt="fallbackImg"
              className="w-full"
              draggable={false}
            />
          }
        >
          <img
            src={"https://cdn.servatom.com/Portfolios/v1/avatar.png"}
            alt="avatar"
            className={` absolute top-[40%] w-1/4 -translate-y-1/2 left-1/2 -translate-x-1/2 z-[5] transition-opacity duration-500 delay-[2s] ${
              isSplineLoaded ? "opacity-100" : "opacity-0"
            }`}
            ref={avatarRef}
            draggable={false}
          />
          <Suspense fallback={<></>}>
            <Spline
              ref={splineRef}
              scene="https://prod.spline.design/P1Fh8JGmucC6L2PK/scene.splinecode"
              className={`w-full aspect-video splineScene -mt-8 relative z-10 ${
                isSplineLoaded ? "" : "hidden"
              }`}
              onLoad={(spline) => {
                setTimeout(() => {
                  setIsSplineLoaded(true);
                }, 2000);
              }}
              onMouseHover={(e) => {
                onMouseHover(e);
              }}
              onMouseLeave={(e) => {
                document.body.style.cursor = "default";
              }}
            />
          </Suspense>
          <div
            className={`${
              isSplineLoaded ? "hidden" : ""
            } flex flex-col justify-end w-full aspect-video`}
          >
            <div className="flex flex-row justify-center items-center gap-4 pb-12 ">
              <IoSettings className="animate-spin text-6xl " />
              <span className="text-5xl font-medium">
                Setting up my desk 🛠️...
              </span>
            </div>
            <img
              src={table}
              alt="table"
              className={`w-full ${
                isSplineLoaded ? "hidden" : ""
              } animate-pulse `}
              draggable={false}
            />
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Hero;
